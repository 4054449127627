<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div class="col s12">
        <v-data-table
          :headers="headers"
          :items="companies"
          :sort-by="startSortBy"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>Companies</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search Companies"
                single-line
                hide-details
              ></v-text-field>
              <v-dialog
                v-model="dialog"
                max-width="500px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <i class="material-icons float-left">add</i> New Company
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.companyName"
                            label="Company Name"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-select
                            v-model="editedItem.companyType"
                            :items="companytypes"
                            item-text="name"
                            item-value="id"
                            label="Company type"
                            outlined
                          ></v-select>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.phone"
                            label="Phone"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.email"
                            label="Email"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.accountExec"
                            label="Account Exec"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.address.line1"
                            label="Address 1"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.address.line2"
                            label="Address 2"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.address.city"
                            label="City"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.address.state"
                            label="State"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.address.zip"
                            label="Zip"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="12"
                        >
                          <v-text-field
                            v-model="editedItem.loanrangelow"
                            type="number"
                            label="Loan Range Low"
                          ></v-text-field>
                          -
                          <v-text-field
                            v-model="editedItem.loanrangehigh"
                            type="number"
                            label="Loan Range High"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="close"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </v-toolbar>
          </template>
          <template v-slot:item.id="{ item }">
            <a :href="'/portal/companies/' + item.id">{{ item.id }}</a>
          </template>
          <template v-slot:item.companyName="{ item }">
            <a :href="'/portal/companies/' + item.companyName">{{ item.companyName }}</a>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <!-- <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon> -->
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </base-section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  // import { Users } from '@/database/users'
  import { Companies } from '@/database/companies'
  import { System } from '@/database/system'
  import { FirebaseResults } from '@/database'

  export default {
    name: 'SectionMap',
    data: () => ({
      deals: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        companyName: '',
        companyType: '',
        accountExec: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
        },
        loanrangehigh: 0,
        loanrangelow: 0,
        loantypes: [],
        propertytypes: [],
      },
      defaultItem: {
        id: '',
        companyName: '',
        companyType: '',
        accountExec: '',
        phone: '',
        email: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
        },
        loanrangehigh: 0,
        loanrangelow: 0,
        loantypes: [],
        propertytypes: [],
      },
      search: '',
      companytype: 'lender',
      users: [],
      filteredCompanies: [],
      companies: [],
      loanofficers: [],
      companytypes: [],
      active: true,
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),
      isNewForm () {
        return this.editedIndex === -1
      },
      formTitle () {
        return this.isNewForm ? 'New Item' : 'Edit Item'
      },
      startSortBy () {
        return 'companyName'
      },
      headers () {
        var header = [
          // {
          //   text: 'id',
          //   align: 'start',
          //   sortable: false,
          //   value: 'id',
          // },
          { text: 'Company Name', value: 'companyName' },
          { text: 'Account Exec', value: 'accountExec' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', value: 'email' },
          { text: 'Company Type', value: 'companyType' },
          { text: 'Loan Types', value: 'loantypelist' },
          { text: 'Property Types', value: 'propertytypelist' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
        
        return header
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      // dialogDelete (val) {
      //   val || this.closeDelete()
      // },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.fetchCompanies(this.active)
        
        System.findOne('companytypes').then(doc => {
          const results = doc.data().data
          this.companytypes = results.map(function(item){
            return { id: item.name, name: item.name }
          })
        })
      },
      runtest () {
        // console.log(this.user.data)
        if (this.usertype === 'borrower'){
          this.usertype = 'loan officer'
        } else {
          this.usertype = 'borrower'
        }
      },
      formatNumber(val) {
        return val.toLocaleString(
          undefined, 
          { minimumFractionDigits: 2 },
        )
      },
      fetchCompanies (companyId, active) {
        Companies.findAll().onSnapshot(docs => {
          this.companies = FirebaseResults.map(docs)
          this.companies.forEach(c => {
            c.loantypelist = c.loantypes.map(function(lt){ return lt.name }).join(',\n')
            c.propertytypelist = c.propertytypes.map(function(pt){ return pt.name }).join(',\n')
          })
        })
      },
      editItem (item) {
        this.editedIndex = this.companies.indexOf(item)
        if (item.address1 && !item.address){
          item.address = { line1: item.address1, line2: item.address2, city: item.addresscity, state: item.addressstate, zip: item.addresszip }
        }
        if (!item.address){
          item.address = { line1: '', line2: '', city: '', state: '', zip: '' }
        }
        
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      // deleteItem (item) {
      //   this.editedIndex = this.desserts.indexOf(item)
      //   this.editedItem = Object.assign({}, item)
      //   this.dialogDelete = true
      // },

      // deleteItemConfirm () {
      //   this.desserts.splice(this.editedIndex, 1)
      //   this.closeDelete()
      // },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      // closeDelete () {
      //   this.dialogDelete = false
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem)
      //     this.editedIndex = -1
      //   })
      // },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.companies[this.editedIndex], this.editedItem)
          console.log(this.editedItem)
          Companies.update(this.editedItem.id, this.editedItem).then(() => {
            console.log('company updated.')
          })
        } else {
          this.companies.push(this.editedItem)
          this.editedItem.createdBy = this.user.data.id
          // const createCompany = Functions.httpsCallable('createCompany')
          // createCompany({ companyName: companyName, companyType: companyType, phone: phone, address1: address1, address2: address2, city: addresscity, state: addressstate, zip: addresszip })
          // return;
          Companies.add(this.editedItem)
            .then(() => {
              console.log('company created')
            })
            .catch(error => {
              console.log('error: ', error)
            })
        }
        this.close()
      },

      getCurrentStatus (statuses) {
        return statuses.slice().sort((a, b) => (a.StatusDate < b.StatusDate) ? 1 : -1)[0].StatusName
      },
      filterCompanies (searchTerm) {
        if (searchTerm.length < 2) {
          this.filteredCompanies = [...this.companies]
        } else {
          this.filteredCompanies = filterResults(searchTerm, this.companies, 'companyName').search()
        }
      },
    },
  }
</script>
